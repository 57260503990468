<template>
    <header class="absolute w-full z-30">
        <div class="w-full px-4 sm:px-6">
            <div class="flex items-center justify-between h-20">
                <!-- Site branding -->
                <div class="shrink-0 mr-5">
                    <!-- Logo -->
                    <a class="block">
                        <img :src="image" class="w-12 h-12" />
                    </a>
                </div>

                <!-- Desktop navigation -->
                <nav class="hidden md:flex md:grow">
                    <!-- Desktop menu links -->
                    <ul class="flex grow flex-wrap items-center font-medium justify-center">
                        <li>
                            <a
                                class="text-white dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
                                @click="scrollto('tournaments')"
                                >Turniere</a
                            >
                        </li>
                        <li>
                            <a
                                class="text-white dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
                                @click="scrollto('discord')"
                                >Discord</a
                            >
                        </li>
                        <li>
                            <a
                                class="text-white dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
                                @click="scrollto('projectv')"
                                >PROJECT V / QUEENS</a
                            >
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'navbar',
    props: {
        image: {
            String,
        },
    },
    methods: {
        scrollto(element) {
            document.getElementById(element).scrollIntoView();
        },
    },
};
</script>
