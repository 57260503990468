<template>
    <header class="absolute w-full z-30">
        <div class="w-full px-4 sm:px-6">
            <div class="flex items-center justify-between h-20">
                <!-- Site branding -->
                <div class="shrink-0 mr-5">
                    <!-- Logo -->
                    <a class="block">
                        <img src="https://cdn.discordapp.com/icons/704715447764779178/a_21be307f8e299b7d5334cabb49cf4cf5.gif" class="w-12 h-12" />
                    </a>
                </div>

                <!-- Desktop navigation -->
                <nav class="hidden md:flex md:grow">
                    <!-- Desktop menu links -->
                    <ul class="flex grow flex-wrap items-center font-medium justify-center">
                        <li>
                            <a
                                class="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out"
                                href="https://valode.community"
                                >Zur Mainpage</a
                            >
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'navbar_external',
    methods: {
        scrollto(element) {
            document.getElementById(element).scrollIntoView();
        },
    },
};
</script>
