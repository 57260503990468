import {createRouter, createWebHistory} from 'vue-router';
import Home from '../views/Homev2.vue';
import LinkedRoles from '../views/LinkedRoles.vue';
import RSO from '../views/RSO.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/linked-roles',
        name: 'LinkedRoles',
        component: LinkedRoles,
    },
    {
        path: '/rso',
        name: 'RSO',
        component: RSO,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
