<template>
    <nav-bar :image="guild?.iconURL" />
    <div class="dark bg-gray-900 grow flex flex-col translate-y-0 transition-transform duration-x ease-in-out translate-x-0">
        <section class="flex text-left items-center m-h-25 mt-20">
            <div class="ml-10-p">
                <h1 v-if="!unknown_state" class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex">Rang Verlinkung</h1>
                <p v-if="!unknown_state" class="text-xl text-gray-400 mb-4">
                    Im Anschluss findest du alle Schritte die ausgeführt werden um die größtmögliche Transparenz zu ermöglichen.
                </p>
            </div>
        </section>
        <section class="flex items-center justify-center" v-if="unknown_state">
            <div class="items-center text-center">
                <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex items-center justify-center">
                    Link abgelaufen. Generier bitte einen neuen Verlinkungsauftrag.
                </h1>
            </div>
        </section>
        <section class="ml-10-p text-left w-full mb-20" v-if="!unknown_state && steps.length">
            <div v-for="(step, i) of steps" :key="step" class="flex items-center mb-8">
                <div
                    class="w-12 h-12 rounded-full dark:bg-opacity-25 flex justify-center items-center text-white dark:text-teal-400 font-medium"
                    style="flex-shrink: 0"
                    :class="
                        !step.success && step.done && !steps[i + 1].done
                            ? 'val_gradient-bg'
                            : steps.some(k => !k.success && k.done) && steps.findIndex(k => !k.success && k.done) < i
                            ? 'bg-gray-3'
                            : step.success
                            ? 'green_gradient-bg'
                            : 'hdev_gradient-bg'
                    "
                >
                    <i v-if="step.success" class="fa-solid fa-check text-white"></i>
                    <i v-else-if="(!step.success && step.done && !steps[i + 1].done) || steps.some(i => !i.success && i.done)" class="fa-solid fa-xmark text-white"></i>
                    <i v-else-if="!step.done && (steps[i - 1]?.done || !steps[i - 1])" class="fa-solid fa-circle-notch text-white fa-spin"></i>
                </div>
                <div class="flex flex-col text-left ml-8 text-white" style="max-width: -webkit-fill-available">
                    <p>{{ getTranslation(`rso.events.${step.name}`) }}</p>
                    <pre style="overflow: auto">{{ step.value ? JSON.stringify(step.value) : null }}</pre>
                </div>
            </div>
        </section>
        <section class="flex items-center justify-center" v-if="!steps.length && !unknown_state">
            <div class="items-center text-center">
                <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex items-center justify-center">Lade Schritte herunter...</h1>
            </div>
        </section>
    </div>
    <footer class="relative bg-gray-900">
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
            <div class="py-12 md:py-16 border-t border-gray-200 dark:border-gray-800 -mt-px">
                <!-- Top area: Blocks -->
                <div class="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
                    <!-- 1st block -->
                    <div class="md:col-span-2 lg:col-span-3">
                        <!-- Logo -->
                        <img :src="guild?.iconURL" class="w-12 h-12" />
                    </div>
                </div>

                <!-- Bottom area -->
                <div class="md:flex md:items-center md:justify-between">
                    <!-- Social links -->
                    <ul class="flex mb-4 md:order-2 md:ml-4 md:mb-0">
                        <li>
                            <a
                                class="flex justify-center items-center text-white dark:bg-gray-800 hover:underline rounded-full transition duration-150 ease-in-out"
                                href="https://twitter.com/PlayValoDE"
                                aria-label="Twitter"
                            >
                                <div class="flex items-center justify-center w-8 h-8 fill-current">
                                    <i class="tooltip fab fa-twitter cl-gradient"></i>
                                </div>
                            </a>
                        </li>
                        <li class="ml-4">
                            <a
                                class="flex justify-center items-center text-white dark:bg-gray-800 hover:underline rounded-full transition duration-150 ease-in-out"
                                href="https://github.com/VALO-DE"
                                aria-label="Github"
                            >
                                <div class="flex items-center justify-center w-8 h-8 fill-current">
                                    <i class="tooltip fab fa-github cl-gradient"></i>
                                </div>
                            </a>
                        </li>
                        <li class="ml-4">
                            <a
                                class="flex justify-center items-center text-white dark:bg-gray-800 hover:underline rounded-full transition duration-150 ease-in-out"
                                href="https://www.tiktok.com/@playvalode"
                                aria-label="TiKkok"
                            >
                                <div class="flex items-center justify-center w-8 h-8 fill-current">
                                    <i class="tooltip fab fa-tiktok cl-gradient"></i>
                                </div>
                            </a>
                        </li>
                        <li class="ml-4">
                            <a
                                class="flex justify-center items-center text-white dark:bg-gray-800 hover:underline rounded-full transition duration-150 ease-in-out"
                                href="https://www.instagram.com/playvalode/"
                                aria-label="Instagram"
                            >
                                <div class="flex items-center justify-center w-8 h-8 fill-current">
                                    <i class="tooltip fab fa-instagram cl-gradient"></i>
                                </div>
                            </a>
                        </li>
                        <li class="ml-4">
                            <a
                                class="flex justify-center items-center text-white dark:bg-gray-800 hover:underline rounded-full transition duration-150 ease-in-out"
                                href="https://www.twitch.tv/playvalode"
                                aria-label="Twitch"
                            >
                                <div class="flex items-center justify-center w-8 h-8 fill-current">
                                    <i class="tooltip fab fa-twitch cl-gradient"></i>
                                </div>
                            </a>
                        </li>
                    </ul>

                    <!-- Middle links -->
                    <div class="text-sm md:order-1 text-gray-700 mb-2 md:mb-0">
                        <a class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out" href="/tos">Terms</a> ·
                        <a class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out" href="/privacy">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>
.background {
    height: 100vh;
    background-image: url('../assets/images/bg.png');
}

.valorant-gradient {
    border-width: 0;
    background: linear-gradient(135deg, #ff4654, #0d141e);
}

.prv-gradient {
    border-width: 0;
    background: linear-gradient(135deg, #9ccf19, #250353);
}

.prv-gradient-section {
    border-width: 0;
    background: linear-gradient(to bottom, #9ccf19, #250353);
}

.prv-queens {
    border-width: 0;
    background: linear-gradient(135deg, #cc0f58, #0a0104);
}

.prv-queens-section {
    border-width: 0;
    background: linear-gradient(to bottom, #250353, #cc0f58);
}

.cl-gradient {
    background: linear-gradient(135deg, #ff1100, #7b0e08);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mod-gradient {
    background: linear-gradient(135deg, #0066ff, #0548ac);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sup-gradient {
    background: linear-gradient(135deg, #3ca1ff, #187ad6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
    .hide-mobile {
        display: none;
    }
}
</style>

<style src="@/assets/cssv2/style.css"></style>

<script>
import NavBar from '../components/navbar.vue';
import {io} from 'socket.io-client';

export default {
    name: 'RSO',
    components: {NavBar},
    data() {
        return {
            steps: [],
            steps_name: null,
            unknown_state: false,
            i18n: {
                rso: {
                    events: {
                        FETCH_TOKENS: 'API Authentifizierungstokens abrufen... (Tokens gekürzt für erweiterte Sicherheit)',
                        FETCH_USERINFO: 'Abrufen grundlegender Informationen über den Spieler...',
                        FETCH_REGION: 'Region des Kontos abrufen...',
                        FETCH_ACCOUNT: 'Abrufen von Kontoinformationen wie Account Level und Name/Tag...',
                        FETCH_MMR: 'Abrufen von MMR Daten...',
                        FETCH_MATCH_HISTORY: 'Alle verfügbaren Spiele des Spielers werden abgerufen... ',
                        DELETE_RSO_DB: 'Löschen der Berechtigungsbestätigung für die Nutzung der VALORANT Daten deines Kontos...',
                        DELETE_STATE_DB: 'Löschen der UUID dieses Vorgangs aus der Datenbank...',
                        APPLY_ROLE: 'Füge dir die passende Rang Rolle auf Discord hinzu... ',
                        PATCHING_STATS: 'Sammeln deiner Statistiken im Hintergrund...',
                        SET_RSO_DB: 'Hinzufügen der Berechtigungsbestätigung für die Nutzung der VALORANT Daten deines Kontos...',
                        DONE: 'Auftrag erfolgreich abgeschlossen :D',
                        SET_LINK_DB: 'Verlinke deinen VALORANT Account mit deinem Discord Account...',
                        WRITING_LOGS: 'Schreiben Logs für diesen Vorgang in die Datenbank...',
                    },
                },
            },
        };
    },
    async created() {
        const socket = io(process.env.VUE_APP_BASE_API_URL, {
            query: {
                rso: this.$route.query.uuid,
            },
        });
        if (!this.$route.query.uuid) this.unknown_state = true;
        socket.on('UNKNOWN_STATE', msg => {
            console.log(msg);
            this.unknown_state = true;
        });
        socket.on('INIT_PLAN', msg => {
            console.log(msg);
            this.steps = msg.steps;
            this.steps_name = msg.type;
        });
        socket.on('STEP_UPDATE', msg => {
            console.log(msg);
            this.steps.splice(
                this.steps.findIndex(i => i.step == msg.step),
                1,
                msg
            );
        });
    },
    methods: {
        localString(num) {
            return num.toLocaleString();
        },
        getCookie(name) {
            const nameEQ = name + '=';
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },
        getTranslation(name) {
            let object = this.i18n;
            const split = name.split('.');
            for (let i = 0; split.length > i; i++) {
                if (typeof object != 'object') {
                    object = null;
                    break;
                }
                object = object[split[i]];
            }
            return object ?? name;
        },
        setCookie(name, value) {
            document.cookie = `${name}=${value}; path=/`;
        },
    },
};
</script>
