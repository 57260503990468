<template>
    <div class="dark font-inter antialiased bg-white text-white bg-gray-900 dark:text-gray-100 tracking-tight">
        <div class="flex flex-col min-h-screen overflow-hidden">
            <nav-bar :image="guild?.iconURL" />
            <main class="grow">
                <section class="background flex items-center justify-center" :class="query.type == 'queens' ? 'prv-queens' : 'prv-gradient'">
                    <div v-if="error" class="max-w-7xl mx-auto px-4 sm:px-6">
                        <div class="pt-32 pb-12 md:pt-40 md:pb-20">
                            <!-- Page header -->
                            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                                <h1 class="h1 font-red-hat-display mb-4">Fehler</h1>
                            </div>
                            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                                <h3 v-if="error.error == 'Not Found'" class="h3 font-red-hat-display mb-4">Die UUID ist ungültig, bitte überprüfe den Link</h3>
                                <h3 v-else class="h3 font-red-hat-display mb-4">Ein unbekannter Fehler ist aufgetrten, melde dich gerne beim Server Team</h3>
                            </div>
                        </div>
                    </div>
                    <div v-else class="max-w-7xl mx-auto px-4 sm:px-6">
                        <div class="pt-32 pb-12 md:pt-40 md:pb-20">
                            <!-- Page header -->
                            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                                <h1 class="h1 font-red-hat-display mb-4">
                                    Verbinde deinen {{ query.type == 'queens' ? 'PROJECT QUEENS' : 'PROJECT V' }} Account mit Discord
                                </h1>
                            </div>
                            <div class="max-w-sm mx-auto grid gap-12 md:gap-x-6 lg:gap-x-12 md:grid-cols-3 items-start md:max-w-none">
                                <div class="relative flex flex-col items-center">
                                    <div
                                        aria-hidden="true"
                                        class="absolute h-px opacity-50 bg-gradient-to-r from-white via-teal-400 to-white dark:from-gray-900 dark:via-teal-400 dark:to-gray-900 hidden md:block"
                                        style="width: calc(100% - 48px); left: calc(50% + 48px); top: 24px"
                                    >
                                        <div class="absolute inset-0 border-t border-dashed border-white dark:border-gray-900"></div>
                                    </div>
                                    <div
                                        class="w-12 h-12 rounded-full dark:bg-opacity-25 flex justify-center items-center text-white dark:text-teal-400 font-medium mb-8 lg:mb-12"
                                        :class="query.type == 'queens' ? 'prv-queens' : 'prv-gradient'"
                                    >
                                        1
                                    </div>
                                </div>

                                <div class="relative flex flex-col items-center">
                                    <div
                                        aria-hidden="true"
                                        class="absolute h-px opacity-50 bg-gradient-to-r from-white via-teal-400 to-white dark:from-gray-900 dark:via-teal-400 dark:to-gray-900 hidden md:block"
                                        style="width: calc(100% - 48px); left: calc(50% + 48px); top: 24px"
                                    >
                                        <div class="absolute inset-0 border-t border-dashed border-white dark:border-gray-900"></div>
                                    </div>
                                    <div
                                        class="w-12 h-12 rounded-full dark:bg-opacity-25 flex justify-center items-center text-white dark:text-teal-400 font-medium mb-8 lg:mb-12"
                                        :class="state_num >= 2 ? (query.type == 'queens' ? 'prv-queens' : 'prv-gradient') : ''"
                                    >
                                        2
                                    </div>
                                </div>

                                <div class="relative flex flex-col items-center">
                                    <div
                                        class="w-12 h-12 rounded-full dark:bg-opacity-25 flex justify-center items-center text-white dark:text-teal-400 font-medium mb-8 lg:mb-12"
                                        :class="state_num >= 3 ? (query.type == 'queens' ? 'prv-queens' : 'prv-gradient') : ''"
                                    >
                                        3
                                    </div>
                                </div>
                            </div>
                            <!-- Contact form -->
                            <form v-if="state_num == 1" class="max-w-xl mx-auto">
                                <div class="flex flex-wrap -mx-3 mb-5">
                                    <div class="w-full px-3">
                                        <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" for="company"
                                            >Link zu deinem PROJECT V Profil oder Platform Name <span class="text-red-600">*</span></label
                                        >
                                        <input
                                            @keyup.enter="searchProfile()"
                                            @submit.prevent="searchProfile()"
                                            ref="profile_link"
                                            type="text"
                                            class="form-input w-full"
                                            placeholder="Link zu deinem PROJECT V Profil / Name"
                                            required
                                        />
                                        <small class="font-small text-red-600 hidden" ref="small_profile">Test</small>
                                    </div>
                                </div>
                                <div class="flex flex-wrap -mx-3 mt-6">
                                    <div class="w-full px-3">
                                        <button
                                            @click="searchProfile()"
                                            type="button"
                                            class="btn text-white -400 w-full flex items-center"
                                            :class="query.type == 'queens' ? 'prv-queens' : 'prv-gradient'"
                                        >
                                            <span>Suchen</span>
                                            <i class="fa-solid fa-arrow-right ml-2" v-if="!loading"></i>
                                            <i class="fa-solid fa-magnifying-glass fa-flip ml-2" v-else></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <form v-if="state_num == 2" class="max-w-xl mx-auto">
                                <div class="py-12 max-w-3xl mx-auto text-center pb-4 md:pb-8">
                                    <h2 class="h2 font-red-hat-display mb-4">Team Auswählen</h2>
                                    <p class="text-xl text-gray-600 dark:text-gray-400">Wähle jetzt im Anschluss aus welches Team du verknüpfen möchtest</p>
                                </div>
                                <div class="flex flex-wrap -mx-3 mb-5">
                                    <div class="w-full px-3">
                                        <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1">Team <span class="text-red-600">*</span></label>
                                        <select class="form-select w-full" required ref="select_team" @submit.prevent="selectTeam()">
                                            <option v-for="team of projectv.teams" :key="team" :value="team.team.id">{{ team.team.name }} ({{ team.team.slug }})</option>
                                        </select>
                                        <small class="font-small text-red-600 hidden" ref="small_team">Test</small>
                                    </div>
                                </div>
                                <div class="flex flex-wrap -mx-3 mt-6">
                                    <div class="w-full px-3">
                                        <button
                                            @click="selectTeam()"
                                            type="button"
                                            class="btn text-white -400 w-full flex items-center"
                                            :class="query.type == 'queens' ? 'prv-queens' : 'prv-gradient'"
                                        >
                                            <span>Auswählen</span>
                                            <i class="fa-solid fa-arrow-right ml-2" v-if="!loading"></i>
                                            <i class="fa-solid fa-magnifying-glass fa-flip ml-2" v-else></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <form v-if="state_num == 3" class="max-w-xl mx-auto">
                                <div class="py-12 max-w-3xl mx-auto text-center pb-4 md:pb-8">
                                    <h2 class="h2 font-red-hat-display mb-4">Verbunden</h2>
                                    <p class="text-xl text-gray-600 dark:text-gray-400">Du bist jetzt verbunden, hier nochmal eine Zusammenfassung</p>
                                </div>
                                <div class="flex flex-wrap -mx-3 mb-5">
                                    <div class="w-full px-3">
                                        <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" for="country">VRC Points </label>
                                        <input type="text" disabled class="form-input w-full" :value="projectv?.metadata?.vrc" />
                                    </div>
                                    <div class="w-full px-3">
                                        <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" for="country">Spiele </label>
                                        <input type="text" disabled class="form-input w-full" :value="projectv?.metadata?.games" />
                                    </div>
                                    <div class="w-full px-3">
                                        <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" for="country">Wins </label>
                                        <input type="text" disabled class="form-input w-full" :value="projectv?.metadata?.wins" />
                                    </div>
                                    <div class="w-full px-3">
                                        <label class="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1" for="country">PROJECT V beigetreten </label>
                                        <input type="text" disabled class="form-input w-full" :value="formatDate(projectv?.metadata?.created)" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </main>
            <footer class="relative">
                <div class="max-w-6xl mx-auto px-4 sm:px-6">
                    <div class="py-12 md:py-16 border-t border-gray-200 dark:border-gray-800 -mt-px">
                        <!-- Footer illustration -->
                        <div class="pointer-events-none -z-1" aria-hidden="true">
                            <svg
                                class="absolute bottom-0 left-0 transform -translate-x-1/2 ml-24 dark:opacity-40"
                                width="800"
                                height="264"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="400" cy="400" r="400" fill="url(#footerglow_paint0_radial)" fill-opacity=".4"></circle>
                                <defs>
                                    <radialGradient
                                        id="footerglow_paint0_radial"
                                        cx="0"
                                        cy="0"
                                        r="1"
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="rotate(90 0 400) scale(315.089)"
                                    >
                                        <stop stop-color="#3ABAB4"></stop>
                                        <stop offset="1" stop-color="#3ABAB4" stop-opacity=".01"></stop>
                                    </radialGradient>
                                </defs>
                            </svg>
                        </div>

                        <!-- Top area: Blocks -->
                        <div class="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
                            <!-- 1st block -->
                            <div class="md:col-span-2 lg:col-span-3">
                                <!-- Logo -->
                                <img src="https://cdn.discordapp.com/icons/704715447764779178/a_21be307f8e299b7d5334cabb49cf4cf5.gif" class="w-12 h-12" />
                            </div>
                        </div>

                        <!-- Bottom area -->
                        <div class="md:flex md:items-center md:justify-between">
                            <!-- Social links -->
                            <ul class="flex mb-4 md:order-2 md:ml-4 md:mb-0">
                                <li>
                                    <a
                                        class="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                                        href="https://twitter.com/PlayValoDE"
                                        aria-label="Twitter"
                                    >
                                        <div class="flex items-center justify-center w-8 h-8 fill-current">
                                            <i class="tooltip fab fa-twitter cl-gradient"></i>
                                        </div>
                                    </a>
                                </li>
                                <li class="ml-4">
                                    <a
                                        class="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                                        href="https://github.com/VALO-DE"
                                        aria-label="Github"
                                    >
                                        <div class="flex items-center justify-center w-8 h-8 fill-current">
                                            <i class="tooltip fab fa-github cl-gradient"></i>
                                        </div>
                                    </a>
                                </li>
                                <li class="ml-4">
                                    <a
                                        class="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                                        href="https://www.tiktok.com/@playvalode"
                                        aria-label="TiKkok"
                                    >
                                        <div class="flex items-center justify-center w-8 h-8 fill-current">
                                            <i class="tooltip fab fa-tiktok cl-gradient"></i>
                                        </div>
                                    </a>
                                </li>
                                <li class="ml-4">
                                    <a
                                        class="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                                        href="https://www.instagram.com/playvalode/"
                                        aria-label="Instagram"
                                    >
                                        <div class="flex items-center justify-center w-8 h-8 fill-current">
                                            <i class="tooltip fab fa-instagram cl-gradient"></i>
                                        </div>
                                    </a>
                                </li>
                                <li class="ml-4">
                                    <a
                                        class="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                                        href="https://www.twitch.tv/playvalode"
                                        aria-label="Twitch"
                                    >
                                        <div class="flex items-center justify-center w-8 h-8 fill-current">
                                            <i class="tooltip fab fa-twitch cl-gradient"></i>
                                        </div>
                                    </a>
                                </li>
                            </ul>

                            <!-- Middle links -->
                            <div class="text-sm md:order-1 text-gray-700 mb-2 md:mb-0">
                                <a class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out" href="/tos">Terms</a> ·
                                <a class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out" href="/privacy">Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<style scoped>
.background {
    min-height: 100vh;
}

.valorant-gradient {
    border-width: 0;
    background: linear-gradient(135deg, #ff4654, #0d141e);
}

.prv-gradient {
    border-width: 0;
    background: linear-gradient(135deg, #9ccf19, #250353);
}

.prv-gradient-section {
    border-width: 0;
    background: linear-gradient(to bottom, #9ccf19, #250353);
}

.prv-queens {
    border-width: 0;
    background: linear-gradient(135deg, #cc0f58, #0a0104);
}

.prv-queens-section {
    border-width: 0;
    background: linear-gradient(to bottom, #250353, #cc0f58);
}

.cl-gradient {
    background: linear-gradient(135deg, #ff1100, #7b0e08);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mod-gradient {
    background: linear-gradient(135deg, #0066ff, #0548ac);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sup-gradient {
    background: linear-gradient(135deg, #3ca1ff, #187ad6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
    .hide-mobile {
        display: none;
    }
}
</style>

<style src="@/assets/cssv2/style.css"></style>

<script>
import NavBar from '../components/navbar_external.vue';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
dayjs.locale('de');
dayjs.extend(localizedFormat);

export default {
    name: 'LinkedRoles',
    components: {
        NavBar,
    },
    data() {
        return {
            query: {},
            state_num: 1,
            loading: false,
            error: null,
        };
    },
    async created() {
        this.query = this.$route.query;
        const state = await (await fetch(`https://valode.community/api/v1/linked-roles/${this.query.uuid}`)).json();
        if (state.error) this.error = state;
        this.state = state;
        if (state.projectv_metadata?.id) {
            this.state_num = 3;
            this.projectv = {
                metadata: state.projectv_metadata,
            };
        }
    },
    methods: {
        formatDate(date) {
            return dayjs(date).format('LLL');
        },
        async searchProfile() {
            if (!this.$refs['small_profile'].classList.contains('hidden')) this.$refs['small_profile'].classList.toggle('hidden');
            let link = this.$refs['profile_link'].value.split('/');
            link = link[link.length - 1];
            if (!link?.length) {
                this.$refs['small_profile'].innerHTML = 'Fehler: Keinen User / Link angegeben';
                this.$refs['small_profile'].classList.toggle('hidden');
                this.loading = false;
                return;
            }
            this.loading = true;
            const user_check = await (await fetch(`https://api.projectv.gg/api/v1/frontend/users/${link}?expand=integrations,profile`).catch(e => e)).json();
            if (user_check.detail == 'API_ERROR.not_found') {
                this.$refs['small_profile'].innerHTML = 'Fehler: User nicht gefunden';
                this.$refs['small_profile'].classList.toggle('hidden');
                this.loading = false;
                return;
            }
            if (
                user_check.integrations?.other?.discord != this.state.userid &&
                user_check.integrations?.other?.discord != `${this.state.user.username}#${this.state.user.discriminator}`
            ) {
                this.$refs['small_profile'].innerHTML =
                    'Fehler: Die Hinterlegte User ID unterscheidet sich mit deiner Discord ID, bitte gebe auf PROJECT V als Discord Verlinkung deine User ID an. Wie du das machst findest du <a href="https://www.giga.de/artikel/discord-ids-user-server-channel-und-message-id-finden/">hier</a>';
                this.$refs['small_profile'].classList.toggle('hidden');
                this.loading = false;
                return;
            }
            const team = await (await fetch(`https://api.projectv.gg/api/v1/frontend/users/${link}/teams?expand=team,team.platforms`).catch(e => e)).json();
            this.projectv = {
                user: user_check,
                teams: team.data,
                team: null,
            };
            this.loading = false;
            this.state_num = 2;
        },
        async selectTeam() {
            this.loading = true;
            if (!this.$refs['select_team'].value?.length) {
                this.$refs['small_team'].innerHTML = 'Fehler: Kein Team ausgewählt, bitte wähle eins aus.';
                this.$refs['small_team'].classList.toggle('hidden');
                this.loading = false;
                return;
            }
            const patch = await (
                await fetch(`https://valode.community/api/v1/linked-roles/link`, {
                    method: 'POST',
                    body: JSON.stringify({
                        uuid: this.query.uuid,
                        id: this.projectv.user.id,
                        name: this.projectv.user.username,
                        team: this.$refs['select_team'].value,
                        created: this.projectv.user.profile.created_at,
                    }),
                }).catch(e => e)
            ).json();
            if (patch?.set != true) {
                this.$refs['small_team'].innerHTML = 'Fehler: Die Daten konnten nicht gesetzt werden, bitte versuch es später nochmal oder melde dich über ein Ticket.';
                this.$refs['small_team'].classList.toggle('hidden');
                this.loading = false;
                return;
            }
            this.projectv.team = this.projectv.teams.find(i => i.team.id == this.$refs['select_team'].value);
            this.projectv.metadata = patch.metadata;
            this.loading = false;
            this.state_num = 3;
        },
    },
};
</script>
